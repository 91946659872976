import { http, createConfig } from 'wagmi';
import { mainnet, sepolia, arbitrum } from 'wagmi/chains';
import { metaMask, walletConnect } from 'wagmi/connectors';

const walletConnectProjectID = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
if (!walletConnectProjectID) {
  throw new Error('NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID is not set');
}

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}

const config = createConfig({
  chains: [mainnet, sepolia, arbitrum],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [arbitrum.id]: http(),
  },
  connectors: [walletConnect({ projectId: walletConnectProjectID }), metaMask({})],
});

export default config;
