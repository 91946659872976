import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faInstagram } from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import Link from 'next/link';

type SocialsProps = {
  grid?: boolean;
  column?: boolean;
  height?: string;
};
export const Socials: React.FC<SocialsProps> = ({ grid, column, height = 'full' }) => {
  return (
    <div
      className={`${grid ? 'flex flex-col gap-2  md:grid md:grid-cols-3 md:gap-4 md:place-items-center p-2' : `flex flex-${column ? 'col' : 'wrap'} ${column ? 'mb-8' : ''} gap-4  justify-evenly  items-center  p-2 `} h-${height}  w-full`}
    >
      <div className='grid p-2 bg-gray-900 border border-blue-400 rounded-lg shadow place-content-center shadow-blue-300 w-fit instagram hover:shadow-blue-500 hover:scale-95'>
        <Link target='_blank' className='w-full twitter -pt-1' href='https://twitter.com/ImmutableOrg'>
          <FontAwesomeIcon icon={faTwitter} className='w-8 h-8' />
        </Link>
      </div>
      <div className='grid p-2 bg-gray-900 border rounded-lg shadow place-content-center border-violet-400 shadow-violet-300 w-fit instagram hover:shadow-violet-500 hover:scale-95'>
        <Link target='_blank' className='w-full -pt-1 discord' href='https://discord.gg/kCsQUJR7U4'>
          <FontAwesomeIcon icon={faDiscord} className='w-8 h-8' />
        </Link>
      </div>
      <div className='grid p-2 bg-gray-900 border border-pink-400 rounded-lg shadow place-content-center shadow-pink-300 w-fit instagram hover:shadow-pink-500 hover:scale-95'>
        <Link
          target='_blank'
          className='w-full -pt-1'
          href='https://instagram.com/immutable_org?igshid=OGQ5ZDc2ODk2ZA=='
        >
          <FontAwesomeIcon icon={faInstagram} className='w-8 h-8' />
        </Link>
      </div>
    </div>
  );
};
