'use client';
import { faCoins, faQuestionCircle, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Sun, Moon } from 'lucide-react';
import { Socials } from '~/Socials';
import { Button } from '~/ui/button';
import { Card } from '~/ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '~/ui/dropdown-menu';
import WalletConnect from '~/wallet-connect';
import NavMenuItem from './item';
import { useTheme } from 'next-themes';
import { usePathname } from 'next/navigation';
import Image from 'next/image';

function NavBarContent() {
  const { setTheme } = useTheme();
  const pathname = usePathname();

  return (
    <div className='flex flex-col h-full gap-4 py-2'>
      <Card className='pt-2 '>
        <div className='!flex py-2 justify-start md:px-0 items-center  flex-col gap-2 '>
          <Image className='w-12 h-12 md:h-24 md:w-24' width={125} height={125} src={'token.svg'} alt='Immutable' />
          <span
            style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
            className='text-center text-white md:my-4 md:text-2xl lg:text-3xl'
          >
            Immutable Organization
          </span>
        </div>
      </Card>
      {/* <span style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}>Post permanently</span> */}
      <div className='flex flex-col w-full gap-4'>
        <NavMenuItem
          color='purple'
          selected={pathname === '/posts'}
          icon={<Image width={20} height={20} src={'token.svg'} alt='Immutable' />}
          page='posts'
        />
        <NavMenuItem
          color='yellow'
          selected={pathname === '/buy'}
          icon={<FontAwesomeIcon icon={faCoins} />}
          page='buy'
        />
        <NavMenuItem
          color='cyan'
          selected={pathname === '/info'}
          icon={<FontAwesomeIcon icon={faQuestionCircle} />}
          page='info'
        />
        <NavMenuItem
          color='emerald'
          selected={pathname === '/whitepaper'}
          icon={<FontAwesomeIcon icon={faFile} />}
          page='whitepaper'
        />
      </div>
      <div className='!m-auto'>
        <div className='flex items-center justify-center gap-4 py-4 '>
          <WalletConnect />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='outline' size='icon'>
                <Sun className='h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' />
                <Moon className='absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' />
                <span className='sr-only'>Toggle theme</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem onClick={() => setTheme('light')}>Light</DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme('dark')}>Dark</DropdownMenuItem>
              <DropdownMenuItem onClick={() => setTheme('system')}>System</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <Socials height='fit' />
      </div>
    </div>
  );
}

export default NavBarContent;
