'use client';

import { usePathname } from 'next/navigation';

function ConditionalBackground() {
  const pathname = usePathname();

  if (pathname === '/posts') {
    return null;
  } else if (pathname === '/') {
    return null;
  } else {
    return <iframe src='/bg/index.html' className='fixed w-screen h-screen2' title='bg' />;
  }
}

export default ConditionalBackground;
