'use client';

import { usePathname } from 'next/navigation';
import Image from 'next/image';
import React from 'react';
import Link from 'next/link';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../ui/sheet';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import NavBarContent from './content';

function TopBar() {
  const pathname = usePathname();

  const renderText = !pathname ? '' : pathname.replace('/', '');

  return (
    <Sheet>
      <div className='flex justify-between border-b border-purple-600 overscroll-none touch-none bg-primary'>
        <div className='flex items-center gap-2 p-2 py-4 text-lg uppercase'>
          <Image width={24} height={24} src={'token.svg'} alt='IMT' />
          <Link href={'/'}>Immutable</Link>/<Link href={pathname}>{renderText}</Link>
        </div>
        <SheetTrigger tabIndex={-1} className='px-4 md:hidden'>
          <HamburgerMenuIcon className='w-8 h-8' />
        </SheetTrigger>
      </div>

      <SheetContent>
        <SheetHeader className='h-full'>
          <NavBarContent />
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}

export default TopBar;
