import Link from 'next/link';
import { cn } from 'utils/utils';

type NavMenuItemProps = {
  page: string;
  color: string;
  icon: React.ReactNode;
  selected: boolean;
};

function NavMenuItem({ page, icon, selected, color }: NavMenuItemProps) {
  return (
    <Link
      href={`/${page}`}
      style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 1))' }}
      className={cn(
        'flex items-center overflow-hidden text-ellipsis p-2 shadow-black text-2xl py-4 shadow-md uppercase bg-transparent hover:text-purple-800 hover:bg-slate-900 hover:border-purple-900  transition-colors duration-200 ease-in-out  gap-4 rounded-md border border-purple-600 w-full',
        `text-${color}-500`,
        selected ? 'bg-slate-950 border-purple-900 text-purple-800' : '',
      )}
    >
      {icon}
      <span className='overflow-hidden text-ellipsis'>{page}</span>
    </Link>
  );
}

export default NavMenuItem;
